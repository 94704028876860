<template>
  <div id="body">
    <div class="fatherBox">
      <div class="headerBox">
        <p>学校列表</p>
      </div>
      <div class="query" >
        <div class="queryLeft">
<!--          <div>编号：
            <el-input class="input" v-model="serchList.mechineName" size="medium" placeholder="请输入编号"></el-input>
          </div>
          <div>状态：
            <el-select class="select" v-model="serchList.state" @change="schoolList(serchList,1)" size="medium"
              placeholder="请选择状态">
              <el-option v-for="item in stateList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <br>
          <div>地区：
            <el-select class="select" v-model="serchList.region" @change="schoolList(serchList,1)" size="medium"
              placeholder="请选择地区">
              <el-option v-for="item in regionList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div> -->
          <div style="width: auto;">学校：
            <el-select class="select" filterable  v-model="serchList.id"  size="medium" placeholder="请选择学校" style="width: auto;">
              <el-option v-for="item in schoolData" :key="item.id" :label="item.schoolName" :value="item.id" >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="queryRight">
          <el-button @click="reset()">重置</el-button>
          <el-button type="primary" @click="schoolList(serchList,1)">查询</el-button>
        </div>
      </div>
      <el-table :data="SchoolInfos" v-loading="loading">
        <el-table-column prop="ID" label="学校编号" align="center" width="120"></el-table-column>
        <el-table-column prop="SchoolName" label="学校名称" :formatter="BindSchool" align="center" width="150"> </el-table-column>
        <el-table-column prop="SchoolName" label="所在地区" align="center"  width="200" :formatter="BindSchool">
          <template slot-scope="scope">
              {{scope.row.pname }}{{scope.row.cname }}{{scope.row.aname }}
          </template>
        </el-table-column>
        <el-table-column prop="eNum" label="视力仪设备(台)" align="center"></el-table-column>
        <el-table-column prop="yNum" label="音视频设备数量(台)" align="center"></el-table-column>
        <el-table-column prop="cNum" label="校门卫士设备数量(台)" align="center"></el-table-column>
        <el-table-column prop="ygyNum" label="验光仪设备(台)" align="center"></el-table-column>
        <el-table-column prop="xlyNum" label="训练仪设备(台)" align="center"></el-table-column>
        <el-table-column prop="qdyNum" label="签到仪设备(台)" align="center"></el-table-column>
        <!-- <el-table-column  prop="mechineNum" label="设备数量(台)" width="360"></el-table-column> -->
        <el-table-column prop="InsertTime" label="加入时间" :formatter="InsertTime" width="160" align="center"></el-table-column>
        <!-- <el-table-column  prop="InsertTime" label="加入时间" width="260"></el-table-column> -->

        <el-table-column label="操作" fixed="right" align="center" width="120">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain v-if="scope.row.TeaName==null?true:false"
              @click="detail(scope.$index, scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" v-show="CountNum > 0">
        <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
          layout="total,prev, pager, next, jumper" :current-page="currpage" :total="CountNum" style="margin-top:20px">
        </el-pagination>
      </div>
    </div>

    <el-dialog title="添加设备" :visible.sync="dialogFormVisible" top="50px" :close-on-click-modal="false">
      <el-form :model="Addfacility" ref="articleForm" style="width:460px;margin:0 auto">
        <el-form-item label="请输入设备编号">
          <el-input v-model="Addfacility.facility" style="width: 260px; float: left" placeholder="请输入设备编号" clearable>
          </el-input>
        </el-form-item>
        <el-form-item style="margin-top:5px">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="Adds">添加</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

<!--    <div>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="数据导入" style="margin: auto;">
          <el-select v-model="form.SchoolID" placeholder="请选择学校" @change="School">
            <el-option v-for="item in SchoolList" :key="item.ID" :label="item.SchoolName" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>
        <el-upload ref="upload" class="file" :action="url" :file-list="file" :auto-upload="false" :on-success="change"
          :limit="1" :headers="{'token':token}" :data="form">
          <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        </el-upload>
        <el-button type="primary" @click="onSubmitee">提交</el-button>

        <br>
        <hr>
        <br>

        <el-form-item label="数据多结果导入" style="margin: auto;">
          <el-select v-model="form.SchoolIDtf" placeholder="请选择学校" @change="Schooltf">
            <el-option v-for="item in SchoolList" :key="item.ID" :label="item.SchoolName" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>
        <el-upload ref="upload" class="file" :action="urltf" :file-list="file" :auto-upload="false"
          :on-success="changetf" :limit="1" :headers="{'token':token}" :data="form">
          <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        </el-upload>
        <el-button type="primary" @click="onSubmiteeTf">提交</el-button>
      </el-form>
    </div>
    <br>

    <div style="background-color: lightcyan;">
      <el-form ref="form" :model="form">
        <el-form-item label="学生导出" style="margin: auto;">
          <el-select v-model="form.stuSchoolchuID" placeholder="请选择学校" @change="Schoolchu">
            <el-option v-for="item in SchoolListt" :key="item.ID" :label="item.SchoolName" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>
        <div class="search">
          <span>时间：</span>
          <el-date-picker class="time" size="medium" type="daterange" v-model="pickerStu" start-placeholder="开始日期"
            range-separator="-" end-placeholder="结束日期" :picker-options="pickerOptions" value-format="yyyy-MM-dd"
            unlink-panels @change="pickerChangeStu">
          </el-date-picker>
        </div>
        <div class="search">
          <span>年级：</span>
          <el-select v-model="form.stuGradechuID" placeholder="请选择年级" @change="SchoolchuGrade">
            <el-option v-for="item in GradeList" :key="item.ID" :label="item.TypeName" :value="item.ID">
            </el-option>
          </el-select>
        </div>
        <div class="search">
          <span>班级：</span>
          <el-select v-model="form.stuClasschuID" placeholder="请选择班级" @change="SchoolchuClass">
            <el-option v-for="item in ClassList" :key="item.ID" :label="item.ClassName" :value="item.ID">
            </el-option>
          </el-select>
        </div>
        <el-button type="primary" @click="onSubmitchuStu">提交</el-button>
      </el-form>
    </div>


    <div style="background-color: antiquewhite;">
      <el-form ref="form" :model="form">
        <el-form-item label="视力测试数据导出" style="margin: auto;">
          <el-select v-model="form.SchoolchuID" placeholder="请选择学校" @change="Schoolchu">
            <el-option v-for="item in SchoolListt" :key="item.ID" :label="item.SchoolName" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>
        <div class="search">
          <span>时间：</span>
          <el-date-picker class="time" size="medium" type="daterange" v-model="picker" start-placeholder="开始日期"
            range-separator="-" end-placeholder="结束日期" :picker-options="pickerOptions" value-format="yyyy-MM-dd"
            unlink-panels @change="pickerChange">
          </el-date-picker>
        </div>
        <div class="search">
          <span>年级：</span>
          <el-select v-model="form.GradechuID" placeholder="请选择年级" @change="SchoolchuGrade">
            <el-option v-for="item in GradeList" :key="item.ID" :label="item.TypeName" :value="item.ID">
            </el-option>
          </el-select>
        </div>
        <div class="search">
          <span>班级：</span>
          <el-select v-model="form.ClasschuID" placeholder="请选择班级" @change="SchoolchuClass">
            <el-option v-for="item in ClassList" :key="item.ID" :label="item.ClassName" :value="item.ID">
            </el-option>
          </el-select>
        </div>
        <el-button type="primary" @click="onSubmitchu">提交</el-button>
      </el-form>
    </div>

 -->
  </div>


</template>

<script>
  import {
    schoolList,
    machineBind,
    excelSearch,
    gradeSearch,
    classSearch,
    excelDaochu,
    excelDaochuStu
  } from '@/api/agentApi.js'
  import request from '@/api/request.js'
  export default {
    data() {
      return { //声明属性变量 可以用this.属性名得到 例如 this.pagesize
        form: {
          SchoolID: '', // 数据导入学校
          SchoolIDtf: '', //数据导入学校24种数据
          SchoolchuID: '', // 学生测试数据导出学校id
          GradechuID: '', // 学生测试数据导出年级id
          ClasschuID: '', // 学生测试数据导出学校id
          stuSchoolchuID: '', // 学生数据导出学校id
          stuGradechuID: '', // 学生数据导出年级id
          stuClasschuID: '', // 学生数据导出学校id
        },
        SchoolInfos: [], // 学校列表
        pagesize: 12, // 每页显示条目个数
        currpage: 1, // 初始页
        CountNum: 0, // 总条数
        loading: false, //加载框
        serchList: {}, //查询列表
        schoolData: [], //学校下拉
        stateList: [], //状态集合
        regionList: [], //地区集合
        dialogFormVisible: false, // 添加设备弹窗
        Addfacility: {
          facility: '' // 添加的设备编号
        },
        SchoolList: [], //数据导入的学校集合
        SchoolListt: [], //数据导出的学校集合
        GradeList: [], //数据导出的年级集合
        ClassList: [], //数据导出的班级集合
        url: this.$url + 'agent/excelStuadd', //上传文件请求adminApi
        urltf: this.$url + 'agent/excelStuaddTf', //上传文件请求adminApi
        file: [], //上传的file数组
        token: localStorage.getItem('token'), //token
        startDate: '', //学生测试数据开始时间
        endDate: '', //学生测试数据结束时间
        startDateStu: '', //学生数据开始时间
        endDateStu: '', //学生数据结束时间
        picker: null, //选择的时间
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
          shortcuts: [{
            text: '最近一周',
         		 onClick(picker) {
              const end = new Date();
              const start = new Date();
        		    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          			 picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近半个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 14);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        pickerStu: null, //选择的时间
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
          shortcuts: [{
            text: '最近一周',
            onClick(pickerStu) {
              const end = new Date();
              const start = new Date();
        		    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          			 pickerStu.$emit('pick', [start, end]);
            }
          }, {
            text: '最近半个月',
          		onClick(pickerStu) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 14);
           			pickerStu.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(pickerStu) {
              const end = new Date();
        		    const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              pickerStu.$emit('pick', [start, end]);
            }
          }]
        }
      }
    },
    mounted() {
      this.schoolList({}, 1) // 获取学校列表
      this.schoolList2() // 下拉框获取学校列表
      //this.schoolExcelList()
    },
    methods: {
      reset() { //重置按钮
        this.serchList = {}
        this.schoolList({}, 1)
      },
      InsertTime(row, column) { // 加入时间过滤
        return row.InsertTime
        // return row.InsertTime.slice(0, 16)
      },
      schoolList(serchJson, currpage) { // 学校列表
        this.loading = true
        const data = serchJson

        data["page"] = currpage //	当前页数
        data["limit"] = this.pagesize // 每页条数

        schoolList(data).then(res => {
          this.SchoolInfos = res.data.List // 学校列表
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.currpage = currpage //分页显示当前页
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      schoolList2() { // 下拉框获取学校列表
        const data = {}
        data["page"] = 1 //	当前页数
        data["limit"] = 1000 // 每页条数
        data["stype"] = 1 // 类型
        data["list"] = 1 // 每页条数
        schoolList(data).then(res => {
          this.schoolData = res.data.List.map((item, index) => {
            return Object.assign({}, {
              'schoolName': item.SchoolName
            }, {
              'id': item.ID
            })
          })
        }).catch(err => {
          console.log(err)
        })
      },
      schoolExcelList() {
        excelSearch().then(res => {
          //console.log(res)
          this.SchoolList = res.data;
          this.SchoolListt = res.data;
        }).catch(err => {
          console.log(err)
        })
      },
      BindSchool(row, column) { // 绑定学校
        return row.SchoolName == null ? '暂无' : row.SchoolName
      },
      detail(index, row) { // 点击详情
        // console.log(row);
        this.$router.push({
          name: 'SchoolList_detail',
          params: {
            Schinfos: row.ID
          }
        })
        localStorage.setItem('SchName', row.SchoolName) // 存学校名字
      },
      Adds() { // 点击添加(添加设备弹窗)
        if (this.Addfacility.facility == '') {
          this.$message('设备编号不能为空')
        } else {
          const data = {
            mechineCode: this.Addfacility.facility
          }
          machineBind(data).then(res => {
            if (res.status.code == 1) {
              this.$message({
                message: '添加成功',
                type: 'success'
              })
              this.Addfacility.facility = '' // 清空
              this.schoolList({}, 1) // 刷新
            } else {
              this.$message.error(res.status.msg)
            }
          }).catch(err => {
            console.log(err)
          })
        }
      },
      handleCurrentChange(val) { // 分页
        this.schoolList({}, val)
        // this.currpage = val
      },
      School(val) { // 数据导入学校选择
        //console.log(val);
        this.SchoolID = val
      },
      Schooltf(val) { // 数据导入24种学校选择
        //console.log(val);
        this.SchoolIDtf = val
      },
      Schoolchu(val) { // 数据导出学校选择
        this.SchoolchuID = val
        const data = {
          schoolID: this.SchoolchuID
        }
        this.form.GradechuID = ""; //清空年级
        this.form.ClasschuID = ""; //清空班级
        gradeSearch(data).then(res => {
          this.GradeList = res.data;
        }).catch(err => {
          console.log(err)
        })
      },
      SchoolchuGrade(val) { // 年级选择
        //console.log(val);
        this.GradechuID = val
        const data = {
          gradeID: this.GradechuID
        }
        this.form.ClasschuID = ""; //清空班级
        classSearch(data).then(res => {
          this.ClassList = res.data;
        }).catch(err => {
          console.log(err)
        })
      },
      SchoolchuClass(val) { // 班级选择
        this.ClasschuID = val
      },
      async onSubmitee() { //提交按钮
        if (this.form.SchoolID) {
          this.$refs.upload.submit(); //手动上传
        } else {
          this.$message.error('请选择学校')
        }
      },
      async onSubmiteeTf() { //提交按钮
        if (this.form.SchoolIDtf) {
          this.$refs.upload.submit(); //手动上传
        } else {
          this.$message.error('请选择学校')
        }
      },
      change(res) { //上传文件成功回调
        //console.log(res.err);
        if (res.err == 10) {
          this.$message.error('服务器错误 无法上传');
          this.form = {};
          this.file = [];
        } else if (res.err == 9) {
          this.$message.error('不是Excel文件，仅支持xls xlsx');
          this.form = {};
          this.file = [];
        } else if (res.err == 8) {
          this.$message.error(' 检查第2行 班级是否正确');
          this.form = {};
          this.file = [];
        } else if (res.err == 7) {
          this.$message.error(' 检查第' + res.num + '行');
          this.form = {};
          this.file = [];
        } else if (res.err == 6) {
          this.$message.error(' 检查第' + res.num + '行 班级 姓名是否存在');
          this.form = {};
          this.file = [];
        } else if (res.err == 5) {
          this.$message.error(' 检查第' + res.num + '行 班级是否创建 ');
          this.form = {};
          this.file = [];
        } else if (res.err == 4) {
          this.$message.error(' 检查第' + res.num + '行 年级是否创建 ');
          this.form = {};
          this.file = [];
        } else if (res.err == 3) {
          this.$message.error(' 班级名称与后台程序不符 请联系程序人员 ');
          this.form = {};
          this.file = [];
        } else if (res.err == 1) {
          this.$message({
            message: '导入成功',
            type: 'success'
          })
          this.file = [];
        }
      },
      changetf(res) { //上传文件成功回调
        console.log(res.code);
        if (res.code == 8) {
          this.$message.error(' 检查第' + res.num + '行编号');
          this.form = {};
          this.file = [];
        }else if (res.code == 9) {
          this.$message.error(' 第' + res.num + '行学生编号不存在');
          this.form = {};
          this.file = [];
        }else if (res.code == 10) {
          this.$message.error(' 检查第' + res.num + '行到第' + (parseInt(res.num) + 8) + '行数据是否正确');
          this.form = {};
          this.file = [];
        }else if(res.code == 1){
          this.$message({
            message: '导入成功',
            type: 'success'
          })
          this.file = [];
        }
      },
      pickerChange(e) { //选择日期
        this.startDate = !!e ? e[0] : ''; //开始时间 如果没有则为空
        this.endDate = !!e ? e[1] : ''; //结束时间 如果没有则为空
      },
      pickerChangeStu(e) { //选择日期
        this.startDateStu = !!e ? e[0] : ''; //开始时间 如果没有则为空
        this.endDateStu = !!e ? e[1] : ''; //结束时间 如果没有则为空
      },
      async onSubmitchuStu() { //学生数据导出提交按钮
        if (this.form.stuSchoolchuID == '') {
          this.$message.error(' 请选择学校');
        } else {
          const data = {
            startDate: this.startDateStu, //开始时间
            endDate: this.endDateStu, //结束时间
            schoolID: this.form.stuSchoolchuID,
            gradeID: this.form.stuGradechuID,
            classID: this.form.stuClasschuID,
          }
          if (this.startDateStu == '') {
            this.$message.error(' 请选择时间');
            return false;
          }

          excelDaochuStu(data).then(res => {
            //window.open 跳转页面
            window.open('http://localhost/syg_pc' + res.data); //res.date是后台返回的url http://localhost/syg_pc替换服务器地址
          }).catch(err => {
            //如果后台返回时 总执行这个err 可能是返回的数据格式不对
            console.log(err)
          })
        }
      },
      async onSubmitchu() { //学生测试数据导出提交按钮
        if (this.form.SchoolchuID == '') {
          // if(typeof(this.SchoolchuID) == 'undefined'){
          this.$message.error(' 请选择学校');
        } else {
          const data = {
            startDate: this.startDate, //开始时间
            endDate: this.endDate, //结束时间
            schoolID: this.form.SchoolchuID,
            gradeID: this.form.GradechuID,
            classID: this.form.ClasschuID,
          }
          if (this.startDate == '') {
            this.$message.error(' 请选择时间');
            return false;
          }
          excelDaochu(data).then(res => {
            //window.open 跳转页面
            window.open('http://localhost/syg_pc' + res.data); //res.date是后台返回的url http://localhost/syg_pc替换服务器地址
          }).catch(err => {
            //如果后台返回时 总执行这个err 可能是返回的数据格式不对
            console.log(err)
          })
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  #body {
    // height: 100%;
    background: #f0f2f5;
  }

  .fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #e9e9e9;

      p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        margin-left: 32px;
      }

      .btn {
        display: flex;
        align-items: center;
        width: 109px;
        height: 32px;
        opacity: 1;
        border-radius: 2px;
      }
    }

    .query {
      width: 98%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-left: 32px;

      .queryLeft {
        width: 75%;
        display: flex;

        div {
          width: 22%;
          text-align: left;

          .input,
          .select {
            width: 60%;
          }
        }
      }
    }
  }
</style>
